import EnumPeriodForAnalytic from '../../../../Common/Models/Enums/EnumPeriodForAnalytic';

export default class EndpointsTimeStatisticAllRequest  {
	ProjectLogGroupId?: number = null;
	FromDate: Date = null;
	ToDate: Date = null;
	Period: EnumPeriodForAnalytic = null;
	constructor(obj?: Partial<EndpointsTimeStatisticAllRequest>) {
		if (obj) {
			Object.assign(this, obj);
		}
	}
}
